import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-ticket-forward',
  templateUrl: './ticket-forward.component.html',
  styleUrls: ['./ticket-forward.component.css']
})
export class TicketForwardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
