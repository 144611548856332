import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ServerService } from '../services/server.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-external-login',
  templateUrl: './external-login.component.html',
  styleUrls: ['./external-login.component.css']
})
export class ExternalLoginComponent implements OnInit {

  sendotp = false;
  sendotpmain = false;
  exten :any;
  constructor(private serverService: ServerService, private router: Router, private route: ActivatedRoute) {

  }

  ngOnInit(): void {
  
    // let extension: any;
    this.route.queryParams
      .subscribe(params => {
       this.exten = params.extension; // { extension: "price" }
      }
      );
    this.userLogin();
  }



  userLogin() {
    Swal.fire({
			html:
				'<div style="display: flex;justify-content: center;"><div class="pong-loader"></div></div>',
		  showCloseButton: false,
			showCancelButton: false,
			showConfirmButton: false,
			focusConfirm: false,
			background: 'transparent',
		});

    // {"operation":"login","moduleType":"login","api_type":"web","element_data":{"extension":"","action":"login_from_erp"}}
    // let ext = localStorage.getItem('aid');
    console.log(this.exten);
    let loginReq: any = new Object();
    let api_req: any = new Object();
    loginReq.action = "login_validation";
    api_req.operation = "login";
    api_req.moduleType = "login";
    api_req.api_type = "web";
    loginReq.action = "login_from_erp";
    loginReq.extension = this.exten;
    api_req.element_data = loginReq;
// console.log(api_req)


    this.serverService.sendServer(api_req).subscribe((response: any) => {
    if (response.result.status == 1) {

        localStorage.setItem('access_token', response.access_token);
        localStorage.setItem('userId', response.result.data.user_id);
        localStorage.setItem('user_name', response.result.data.user_name);
        localStorage.setItem('agent_name', response.result.data.agent_name);
        localStorage.setItem('user_type', response.result.data.userType);
        localStorage.setItem('profile_image', response.result.data.profile_image);
        localStorage.setItem('logo_image', response.result.data.logo_image);
        localStorage.setItem('small_logo_image', response.result.data.small_logo_image);
        localStorage.setItem('theme', response.result.data.theme);
        localStorage.setItem('layout', response.result.data.layout);
        localStorage.setItem('timezone_id', response.result.data.timezone_id);
        localStorage.setItem('admin_id', response.result.data.admin_id);
        localStorage.setItem('dsk_access', response.result.data.dsk_access);
        localStorage.setItem('hardware_id', response.result.data.hardware_id);
        localStorage.setItem('has_external_contact', response.result.data.has_external_contact);
        localStorage.setItem('external_contact_url', response.result.data.external_contact_url);
        localStorage.setItem('show_caller_id', response.result.data.show_caller_id);
        localStorage.setItem('has_reports', response.result.data.reports);
        localStorage.setItem('whatsapp_account', response.result.data.whatsapp_account);
        localStorage.setItem('fb_account', response.result.data.facebook_account);
        localStorage.setItem('predective_dialer_behave', response.result.data.predective_dialer_behave);
        localStorage.setItem('crm_type', response.result.data.crm_type);
        localStorage.setItem('price_sms', response.result.data.price_sms);
        localStorage.setItem('has_line', response.result.data.has_fax);
        localStorage.setItem('encAdmin', response.result.data.encAdmin);
        localStorage.setItem('encUser', response.result.data.encUser);

        // localStorage.setItem('has_tele', response.result.data.has_telegram);
        localStorage.setItem('N_token', '');
        localStorage.setItem('company_name', response.result.data.company_name);
        localStorage.setItem('reseller', response.result.data.reseller);
        localStorage.setItem('ext_int_status', response.result.data.ext_int_status);

        this.router.navigate(['/mc']);
        setTimeout(() => {
          window.location.reload();                 
          }, 1000);

      }
      else {

          Swal.fire({
            title: 'Login Failed',
            text: "Sorry, We can't able to Log you right now,Please Login manually ",
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Login'
          }).then((result) => {
            // if (result.value) {
              localStorage.removeItem("access_token");
         localStorage.removeItem("user_name");
        localStorage.removeItem("user_type");
        localStorage.clear();
        // this.router.navigate(['/login']);
             this.router.navigate(['login']);
             setTimeout(() => {
             window.location.reload();                 
             }, 1000);
            // }
          })

        


      }

    },
      (error) => {
        console.log(error);
      });


  }


}
