import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServerService } from '../services/server.service';
import Swal from 'sweetalert2';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
declare var $: any;
declare var iziToast: any;
@Component({
  selector: 'app-erp-customer-profiles',
  templateUrl: './erp-customer-profiles.component.html',
  styleUrls: ['./erp-customer-profiles.component.css']
})
export class ErpCustomerProfilesComponent implements OnInit {
  Customerlist;
  new_Customerlist;
  recordNotFound;
  new_Customerlist_all;
  start_list;
  end_list;
  showdatalist=false;
  admin_id;
  editCusEmail=[]; 
  editCusEmail2=[]; 
  cus_id;
  admin_permission;
  user_type;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  constructor(private serverService: ServerService, private router: Router) { }
  editadd(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;    
      
        if ((value || '').trim()) {
       
          // Swal.fire({
          //   title: 'Confirm to add',
          //   text: "Just click Confirm to Add",
          //   icon: 'info',
          //   showCancelButton: true,
          //   confirmButtonColor: '#3085d6',
          //   cancelButtonColor: '#d33',
          //   confirmButtonText: 'Confirm'
          // }).then((result) => {
          //   if (result.value) {
            this.editCusEmail.push({ name: value.trim() });
            this.editCusEmail2.push({ name: value.trim() });
          //   }else{

          //   }
  
          // });
        }
        if (input) {
          input.value = '';
        }   
  }

  editremove(code): void {  
    const index = this.editCusEmail2.indexOf(code);
    if (index >= 0) {
      this.editCusEmail2.splice(index, 1);
    }
  }

  ngOnInit() {
    this.admin_id = localStorage.getItem('admin_id');
    this.admin_permission = localStorage.getItem('admin_permision');
		this.user_type = localStorage.getItem('user_type');

    if (this.user_type == 'Super Admin') {
			this.user_type = 1;          
		}
		else if (this.user_type == 'Admin' || this.admin_permission =='1') {
			this.user_type = 2;		
		}
		else {
			this.user_type = 3;
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'You have no access view this page!',
      });
      setTimeout(() => {
        this.router.navigate(['/']);
      }, 2000);
      return false;
		}
    this.displaytickets();

  }
  displaytickets(){
    Swal.fire({
			html:
				'<div style="display: flex;justify-content: center;"><div class="pong-loader"></div></div>',
		    showCloseButton: false,
			showCancelButton: false,
			showConfirmButton: false,
			focusConfirm: false,
			background: 'transparent',
		});
    let api_req:any = '{"operation":"ticket","moduleType":"ticket","api_type":"web","access_token":"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJ1YXRhc3NhYWJsb3ljY2FwaS5tY29ubmVjdGFwcHMuY29tIiwiYXVkIjoidWF0YXNzYWFibG95Y2NhcGkubWNvbm5lY3RhcHBzLmNvbSIsImlhdCI6MTYzMzY3Mjk3MCwibmJmIjoxNjMzNjcyOTcwLCJleHAiOjE2MzM2OTA5NzAsImFjY2Vzc19kYXRhIjp7InRva2VuX2FjY2Vzc0lkIjoiMTI5OCIsInRva2VuX2FjY2Vzc05hbWUiOiJhc3NhYWJsb3ljY1VBVCIsInRva2VuX2FjY2Vzc1R5cGUiOiIyIn19.Ccqct2JwfmSaSA9q8z_F7X0ikbDoJgKm-2cnpOLJvsc","element_data":{"action":"geterpCustomerDetasils","admin_id":""}}';
  // console.log(api_req)
    this.serverService.sendServer(api_req).subscribe((response:any) => {
  Swal.close();

      if(response.result.status == true){   
        this.new_Customerlist = [];     
        this.Customerlist = response.result.data;
        this.Customerlist.forEach(element => {
          var splitted = element.email.split(",");
          this.new_Customerlist.push({ customerName: element.customerName, email: splitted,customerId:element.customerId,customerPhone:element.customerPhone });

        });
        this.new_Customerlist_all=this.new_Customerlist;
        this.start_list = 0;
        this.end_list = 20; 
        this.pagedropdown(this.start_list,this.end_list)
        this.showdatalist = true;
      } else {
        this.recordNotFound = true;
      }
    }, 
    (error)=>{
        console.log(error);
    });
  }

  pagedropdown(start,end){       
    var i, j, chunk = 20;
    for (i = start, j = end; i < j; i += chunk) {
      this.new_Customerlist = this.new_Customerlist_all.slice(i, i + chunk);          
    }
  }
  
   nextPage(){
    this.start_list = this.start_list+20;
    this.end_list = this.end_list+20;        
    this.pagedropdown(this.start_list,this.end_list);
  }
  
  prevPage(){
    this.start_list = this.start_list-20;
    this.end_list = this.end_list-20;      
    this.pagedropdown(this.start_list,this.end_list);
  }
   searchChats(data) {
         
           this.showdatalist = true;

if(data.length>2){
        this.new_Customerlist = this.new_Customerlist_all.filter(
          book => book.customerName.toLowerCase().includes(data.toLowerCase()));
          if(this.new_Customerlist=='' || this.new_Customerlist==null)
              this.showdatalist = false;

              
    }
              if(data==''){
                this.start_list = 0;
                this.end_list = 20; 
                this.pagedropdown(this.start_list,this.end_list)
              }   
      }


      editCustmor(id) {
        let access_token: any = localStorage.getItem('access_token');
    
    
        // {"operation":"getAuxcode_data", "moduleType": "contact", "api_type": "web", "access_token":"", "element_data":{"action":"edit_aux_code","cat_id":"6","admin_id":"1203"}}
        Swal.fire({
          html:
            '<div style="display: flex;justify-content: center;"><div class="pong-loader"></div></div>',
            showCloseButton: false,
          showCancelButton: false,
          showConfirmButton: false,
          focusConfirm: false,
          background: 'transparent',
        });
        let api_req: any = '{"operation":"ticket", "moduleType": "ticket", "api_type": "web", "access_token":"' + access_token + '", "element_data":{"action":"editCustomer","customer_id":"' + id + '","admin_id":"' + this.admin_id + '"}}';
    
        this.serverService.sendServer(api_req).subscribe((response: any) => {
          Swal.close();
          if (response.result.status == true) {
            var agent_data = response.result.data;
            this.editCusEmail = [];
            //   this.editDept.setValue({
            //      'department_name' : agent_data.auxcode_name,
            //  });
            var edit_splits;
            if(agent_data.email!='' && agent_data.email!=null){
                edit_splits = agent_data.email.split(',');
         
            edit_splits.forEach(element => {
              this.editCusEmail.push({ name: element });
            });
            }
            console.log(this.editCusEmail);
            this.cus_id = agent_data.customerId;
            $('#cus_name').val(agent_data.customerName);
            $('#cus_num').val(agent_data.customerPhone); 
            $('#cus_country').val(agent_data.country);
            $('#cus_code').val(agent_data.customerCode);
           
           
    
    
            $('#edit_cusform').modal('show');
            //  this.dept_settings();
          } else {
    
            iziToast.warning({
              message: "Customer Data not retrived. Please try again",
              position: 'topRight'
            });
    
          }
        },
          (error) => {
            console.log(error);
          });
      }
      UpdateCustomer() {

       
        let access_token: any = localStorage.getItem('access_token');
    
        var new_array = [];
        this.editCusEmail.forEach(element => {
          new_array.push(element.name);
        });
        var agent_dept = new_array.join(",");

        var from_address=[];
        this.editCusEmail2.forEach(element => {
          from_address.push(element.name);
        });
        var added_from=from_address.join(",");
        let CusName=$('#cus_name').val();
        let CusPhone=$('#cus_num').val();
        let CusCountry=$('#cus_country').val();
        let CusCode=$('#cus_code').val();

        $('#edit_cusform').modal('hide');  
        if(added_from==''||added_from==null){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'You have not added Email ID',
          });
          return false;
      }
        this.editCusEmail=[];
        this.editCusEmail2=[];
        Swal.fire({
            title: 'Confirm to Add',
            text: "Do you want to add the included email id? can't able to revert this",
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm'
          }).then((result) => {
            if (result.value) {
              Swal.fire({
                html:
                  '<div style="display: flex;justify-content: center;"><div class="pong-loader"></div></div>',
                  showCloseButton: false,
                showCancelButton: false,
                showConfirmButton: false,
                focusConfirm: false,
                background: 'transparent',
              });
             let api_req: any = '{"operation":"ticket", "moduleType": "ticket", "api_type": "web", "access_token":"' + access_token + '", "element_data":{"action":"changeCustomer","from_mail":"' + added_from + '","customer_id":"' + this.cus_id + '","customer_email":"' + agent_dept + '","customer_name":"' + CusName + '","customer_code":"' + CusCode + '","customer_country":"' + CusCountry + '","customer_phone":"' + CusPhone + '","admin_id":"' + this.admin_id + '"}}';
              this.serverService.sendServer(api_req).subscribe((response: any) => {
              Swal.close();
                if (response.result.status == 1) {
                       
                  iziToast.success({
                    message: "Customer Profile updated successfully",
                    position: 'topRight'
                  });
                  this.displaytickets();
                } else {
          
                  iziToast.warning({
                    message: "WrapUp not updated. Please try again",
                    position: 'topRight'
                  });
          
                }
          
              },
                (error) => {
                  iziToast.error({
                    message: "Sorry, some server issue occur. Please contact admin",
                    position: 'topRight'
                  });
                  console.log(error);
                });
            }else{
             
            }
  
          });
    //     let api_req: any = '{"operation":"ticket", "moduleType": "ticket", "api_type": "web", "access_token":"' + access_token + '", "element_data":{"action":"changeCustomer","from_mail":"' + added_from + '","customer_id":"' + this.cus_id + '","customer_email":"' + agent_dept + '","customer_name":"' + CusName + '","customer_code":"' + CusCode + '","customer_country":"' + CusCountry + '","customer_phone":"' + CusPhone + '","admin_id":"' + this.admin_id + '"}}';
    // console.log(api_req);
    // return false;
    //     this.serverService.sendServer(api_req).subscribe((response: any) => {
    //       if (response.result.status == 1) {
    //         $('#edit_cusform').modal('hide');         
    //         iziToast.success({
    //           message: "Customer Profile updated successfully",
    //           position: 'topRight'
    //         });
    //         this.displaytickets();
    //       } else {
    
    //         iziToast.warning({
    //           message: "WrapUp not updated. Please try again",
    //           position: 'topRight'
    //         });
    
    //       }
    
    //     },
    //       (error) => {
    //         iziToast.error({
    //           message: "Sorry, some server issue occur. Please contact admin",
    //           position: 'topRight'
    //         });
    //         console.log(error);
    //       });
      }
}
