import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ticket-compose',
  templateUrl: './ticket-compose.component.html',
  styleUrls: ['./ticket-compose.component.css']
})
export class TicketComposeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
